import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
 import { AuthComponent } from './theme/layout/auth/auth.component';
//import { AuthSigninComponent } from './demo/pages/authentication/auth-signin/auth-signin.component';




const routes: Routes = [
  {path: '', 
  redirectTo: 'auth/signin', 
  pathMatch: 'full'
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  }, 
    {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/extra/sample-page/sample-page.module').then(m => m.SamplePageModule)
      },
      {
        path: 'lead',
        loadChildren: () => import('./demo/pages/lead/lead.module').then(m => m.LeadModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./demo/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'sucursal',
        loadChildren: () => import('./demo/pages/sucursal/sucursal.module').then(m => m.SucursalModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./demo/pages/usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {
        path: 'venta',
        loadChildren: () => import('./demo/pages/venta/venta.module').then(m => m.VentaModule)
      },
      {
        path: 'variable/:id',
        loadChildren: () => import('./demo/pages/variable/variable-edit/variable-edit.module').then(m => m.VariableEditModule)
      },
      {
        path: 'comision',
        loadChildren: () => import('./demo/pages/comision/comision.module').then(m => m.ComisionModule)
      },
      {
        path: 'comprod',
        loadChildren: () => import('./demo/pages/com-prod/com-prod.module').then(m => m.ComProdModule)
      },
      {
        path: 'precomision',
        loadChildren: () => import('./demo/pages/precomision/precomision.module').then(m => m.PrecomisionModule)
      },
      {
        path: 'excel',
        loadChildren: () => import('./demo/pages/excel/excel.module').then(m => m.ExcelModule)
      } ,
      {
        path: 'reporte',
        loadChildren: () => import('./demo/pages/reporte/reporte.module').then(m => m.ReporteModule)
      }    
    ]
  }


];

@NgModule({

  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
