<div *ngIf="this.isConfig" id="styleSelector" class="menu-styler" [ngClass]="{'open': styleSelectorToggle}">
  <div class="style-toggler">
    <a href="javascript:" (click)="styleSelectorToggle = !styleSelectorToggle"></a>
  </div>
  <div class="style-block">
    <h6 class="mb-2">Live Menu Customizer</h6>
    <hr class="my-0">
    <h6 *ngIf="dattaConfig['pre-layout'] !== 'layout-8'">Layouts</h6>
    <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8'" class="theme-color layout-type">
      <a href="javascript:" [ngClass]="{'active': layoutType === 'menu-dark'}" (click)="setLayout('menu-dark')" data-value="menu-dark" ngbTooltip="Default Layout"><span></span><span></span></a>
      <a href="javascript:" [ngClass]="{'active': layoutType === 'menu-light'}" (click)="setLayout('menu-light')" data-value="menu-light" ngbTooltip="Light"><span></span><span></span></a>
      <a href="javascript:" [ngClass]="{'active': layoutType === 'dark'}" (click)="setLayout('dark')" data-value="dark" ngbTooltip="Dark"><span></span><span></span></a>
      <a href="javascript:" [ngClass]="{'active': layoutType === 'reset'}" (click)="setLayout('reset')" data-value="reset" ngbTooltip="Reset">Reset to Default</a>
    </div>
    <h6>Prebuild Layout</h6>
    <p class="f-12"><span class="text-c-red">*</span> in Prebuild Layout you redirect to new page</p>
    <div class="theme-color prelayout-color">
      <a routerLink="/layout/layout-2/" class="" data-value="l2" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-2-2/" data-value="l2-2" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-3/" data-value="l3" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-4/" data-value="l4" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-4-2/" data-value="l4-2" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-5h/" data-value="l5-h" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/nav-color/" data-value="l-lite" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-6/" data-value="l6" target="_blank"><span></span><span></span></a>
      <a routerLink="/layout/layout-8/" data-value="l8" target="_blank"><span></span><span></span></a>
    </div>
    <div class="form-group mb-3">
      <div class="switch switch-primary d-inline m-r-10">
        <input type="checkbox" id="icon-colored" [(ngModel)]='isColoredIcon' (change)="setColoredIcon($event)"> <!--[checked]=""-->
        <label for="icon-colored" class="cr"></label>
        </div>
      <label>Icon Color</label>
    </div>

    <ngb-tabset type="pills">
      <ngb-tab title="Color" class="mb-3">
        <ng-template ngbTabContent *ngIf="dattaConfig['pre-layout'] !== 'layout-6'">
          <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'" [usePSClass]="'datta'" [disabled]="null" [autoPropagation] ="true">
            <h6>header background</h6>
            <div class="theme-color header-color">
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-default'}" (click)="setHeaderBackground('header-default')" data-value="header-default"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-blue'}" (click)="setHeaderBackground('header-blue')" data-value="header-blue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-red'}" (click)="setHeaderBackground('header-red')" data-value="header-red"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-purple'}" (click)="setHeaderBackground('header-purple')" data-value="header-purple"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-lightblue'}" (click)="setHeaderBackground('header-lightblue')" data-value="header-lightblue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-dark'}" (click)="setHeaderBackground('header-dark')" data-value="header-dark"><span></span><span></span></a>
            </div>
            <h6>Menu background</h6>
            <div class="theme-color navbar-color">
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-default'}" (click)="setNavbarBackground('navbar-default')" data-value="navbar-default"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-blue'}" (click)="setNavbarBackground('navbar-blue')" data-value="navbar-blue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-red'}" (click)="setNavbarBackground('navbar-red')" data-value="navbar-red"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-purple'}" (click)="setNavbarBackground('navbar-purple')" data-value="navbar-purple"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-lightblue'}" (click)="setNavbarBackground('navbar-lightblue')" data-value="navbar-lightblue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-dark'}" (click)="setNavbarBackground('navbar-dark')" data-value="navbar-dark"><span></span><span></span></a>
            </div>
            <h6 *ngIf="dattaConfig['layout'] !== 'horizontal'">Menu Brand Color</h6>
            <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="theme-color brand-color">
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-default'}" (click)="setBrandBackground('brand-default')" data-value="brand-default"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-blue'}" (click)="setBrandBackground('brand-blue')" data-value="brand-blue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-red'}" (click)="setBrandBackground('brand-red')" data-value="brand-red"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-purple'}" (click)="setBrandBackground('brand-purple')" data-value="brand-purple"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-lightblue'}" (click)="setBrandBackground('brand-lightblue')" data-value="brand-lightblue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-dark'}" (click)="setBrandBackground('brand-dark')" data-value="brand-dark"><span></span><span></span></a>
            </div>
            <h6 *ngIf="dattaConfig['layout'] !== 'horizontal'">Navbar Image</h6>
            <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="theme-color navbar-images">
              <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-1'}" (click)="setBackgroundImage('navbar-image-1')" data-value="navbar-image-1"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-2'}" (click)="setBackgroundImage('navbar-image-2')" data-value="navbar-image-2"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-3'}" (click)="setBackgroundImage('navbar-image-3')" data-value="navbar-image-3"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-4'}" (click)="setBackgroundImage('navbar-image-4')" data-value="navbar-image-4"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-5'}" (click)="setBackgroundImage('navbar-image-5')" data-value="navbar-image-5"><span></span><span></span></a>
            </div>
          </perfect-scrollbar>
        </ng-template>
        <ng-template ngbTabContent *ngIf="dattaConfig['pre-layout'] === 'layout-6'">
          <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'" [usePSClass]="'datta'" [disabled]="null" [autoPropagation] ="true">
            <h6>Background Color</h6>
            <div class="theme-color laybg-color small">
              <a href="javascript:" [ngClass]="{'active': headerBackColor === '#04a9f5'}" (click)="setHeaderBackColor('#04a9f5')" data-value="#04a9f5" style="background:#04a9f5"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === '#ff5252'}" (click)="setHeaderBackColor('#ff5252')" data-value="#ff5252" style="background:#ff5252"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === '#9575CD'}" (click)="setHeaderBackColor('#9575CD')" data-value="#9575CD" style="background:#9575CD"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === '#23b7e5'}" (click)="setHeaderBackColor('#23b7e5')" data-value="#23b7e5" style="background:#23b7e5"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === '#424448'}" (click)="setHeaderBackColor('#424448')" data-value="#424448" style="background:#424448"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)')" style="background:linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #899FD4 0%, #A389D4 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #899FD4 0%, #A389D4 100%)')" style="background:linear-gradient(to right, #899FD4 0%, #A389D4 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #4facfe 0%, #00f2fe 100%)')" style="background:linear-gradient(to right, #4facfe 0%, #00f2fe 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #667eea 0%, #764ba2 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #667eea 0%, #764ba2 100%)')" style="background:linear-gradient(to right, #667eea 0%, #764ba2 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #f77062 0%, #fe5196 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #f77062 0%, #fe5196 100%)')" style="background:linear-gradient(to right, #f77062 0%, #fe5196 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #9be15d 0%, #00e3ae 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #9be15d 0%, #00e3ae 100%)')" style="background:linear-gradient(to right, #9be15d 0%, #00e3ae 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #b224ef 0%, #7579ff 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #b224ef 0%, #7579ff 100%)')" style="background:linear-gradient(to right, #b224ef 0%, #7579ff 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #0acffe 0%, #495aff 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #0acffe 0%, #495aff 100%)')" style="background:linear-gradient(to right, #0acffe 0%, #495aff 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)')" style="background:linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #fe5d70 0%, #fe909d 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #fe5d70 0%, #fe909d 100%)')" style="background:linear-gradient(to right, #fe5d70 0%, #fe909d 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #0ac282 0%, #0df3a3 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #0ac282 0%, #0df3a3 100%)')" style="background:linear-gradient(to right, #0ac282 0%, #0df3a3 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #fe9365 0%, #feb798 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #fe9365 0%, #feb798 100%)')" style="background:linear-gradient(to right, #fe9365 0%, #feb798 100%)"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)'}" (click)="setHeaderBackColor('linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)')" data-value="linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)" style="background:linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)"></a>
            </div>
            <h6>Background Image</h6>
            <hr>
            <div class="theme-color bg-images">
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg1.jpg\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg1.jpg\')')" style="background-image:url('assets/images/bg-images/bg1.jpg'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg3.jpg\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg3.jpg\')')" style="background-image:url('assets/images/bg-images/bg3.jpg'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg4.jpg\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg4.jpg\')')" style="background-image:url('assets/images/bg-images/bg4.jpg'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg5.jpg\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg5.jpg\')')" style="background-image:url('assets/images/bg-images/bg5.jpg'); background-size: 45px 30px;"></a>
            </div>
            <h6>Background Pattern</h6>
            <hr>
            <div class="theme-color bg-images pattern">
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/1.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/1.png\')')" style="background-image:url('assets/images/bg-images/1.png'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/2.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/2.png\')')" style="background-image:url('assets/images/bg-images/2.png'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/3.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/3.png\')')" style="background-image:url('assets/images/bg-images/3.png'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/4.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/4.png\')')" style="background-image:url('assets/images/bg-images/4.png'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/5.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/5.png\')')" style="background-image:url('assets/images/bg-images/5.png'); background-size: 45px 30px;"></a>
              <a href="javascript:" [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/6.png\')'}" (click)="setHeaderBackColor('url(\'assets/images/bg-images/6.png\')')" style="background-image:url('assets/images/bg-images/6.png'); background-size: 45px 30px;"></a>
            </div>
          </perfect-scrollbar>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Layouts" *ngIf="dattaConfig['layout'] !== 'horizontal'">
        <ng-template ngbTabContent>
          <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'" [usePSClass]="'datta'">
            <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'" class="form-group mb-0">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="theme-rtl" [(ngModel)]='rtlLayout' (change)="setRtlLayout($event)">
                <label for="theme-rtl" class="cr"></label>
              </div>
              <label>RTL</label>
            </div>
            <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'" class="form-group mb-0">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="menu-fixed" [(ngModel)]='menuFixedLayout' (change)="setMenuFixedLayout($event)">
                <label for="menu-fixed" class="cr"></label>
              </div>
              <label>Menu Fixed</label>
            </div>
            <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'" class="form-group mb-0">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="header-fixed" [(ngModel)]='headerFixedLayout' (change)="setHeaderFixedLayout($event)">
                <label for="header-fixed" class="cr"></label>
              </div>
              <label>Header Fixed</label>
            </div>
            <div class="form-group mb-0">
            <div class="switch switch-primary d-inline m-r-10">
              <input type="checkbox" id="box-layouts" [(ngModel)]='boxLayout' (change)="setBoxLayout($event)">
              <label for="box-layouts" class="cr"></label>
            </div>
            <label>Box Layouts</label>
          </div>
          </perfect-scrollbar>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Extra">
        <ng-template ngbTabContent>
          <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'" [usePSClass]="'datta'">
            <h6>Menu Dropdown Icon</h6>
            <div class="theme-color">
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in-1" id="drpicon-1" checked (click)="setMenuDropdownIcon('style1')">
                  <label for="drpicon-1" class="cr"><i class="feather icon-chevron-right"></i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <label>
                    <input type="radio" name="radio-in-1" id="drpicon-2" (click)="setMenuDropdownIcon('style2')">
                    <label for="drpicon-2" class="cr"><i class="feather icon-chevrons-right"></i></label>
                  </label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in-1" id="drpicon-3" (click)="setMenuDropdownIcon('style3')">
                  <label for="drpicon-3" class="cr"><i class="feather icon-plus"></i></label>
                </div>
              </div>
            </div>
            <h6>Menu List Icon</h6>
            <div class="theme-color">
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-1" (click)="setMenuListIcon('style1')">
                  <label for="subitem-1" class="cr"><i class=" "> </i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-2" (click)="setMenuListIcon('style2')">
                  <label for="subitem-2" class="cr"><i class="feather icon-minus"></i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-3" (click)="setMenuListIcon('style3')">
                  <label for="subitem-3" class="cr"><i class="feather icon-check"></i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-4" (click)="setMenuListIcon('style4')">
                  <label for="subitem-4" class="cr"><i class="icon feather icon-corner-down-right"></i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-5" (click)="setMenuListIcon('style5')">
                  <label for="subitem-5" class="cr"><i class="icon feather icon-chevrons-right"></i></label>
                </div>
              </div>
              <div class="form-group d-inline">
                <div class="radio radio-primary d-inline">
                  <input type="radio" name="radio-in" id="subitem-6" (click)="setMenuListIcon('style6')">
                  <label for="subitem-6" class="cr"><i class="icon feather icon-chevron-right"></i></label>
                </div>
              </div>
            </div>
            <h6>Active Color</h6>
            <div class="theme-color active-color small">
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-default'}" (click)="setNavActiveColor('active-default')" data-value="active-default"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-blue'}" (click)="setNavActiveColor('active-blue')" data-value="active-blue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-red'}" (click)="setNavActiveColor('active-red')" data-value="active-red"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-purple'}" (click)="setNavActiveColor('active-purple')" data-value="active-purple"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-lightblue'}" (click)="setNavActiveColor('active-lightblue')" data-value="active-lightblue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navActiveColor === 'active-dark'}" (click)="setNavActiveColor('active-dark')" data-value="active-dark"><span></span><span></span></a>
            </div>
            <h6 *ngIf="dattaConfig['layout'] !== 'horizontal'">Menu Title Color</h6>
            <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="theme-color title-color small">
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-default'}" (click)="setNavTitleColor('title-default')" data-value="title-default"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-blue'}" (click)="setNavTitleColor('title-blue')" data-value="title-blue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-red'}" (click)="setNavTitleColor('title-red')" data-value="title-red"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-purple'}" (click)="setNavTitleColor('title-purple')" data-value="title-purple"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-lightblue'}" (click)="setNavTitleColor('title-lightblue')" data-value="title-lightblue"><span></span><span></span></a>
              <a href="javascript:" [ngClass]="{'active': navTitleColor === 'title-dark'}" (click)="setNavTitleColor('title-dark')" data-value="title-dark"><span></span><span></span></a>
            </div>
            <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="form-group mb-0">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="caption-hide" [(ngModel)]='menuTitleHide' (change)="hideMenuTitle($event)">
                <label for="caption-hide" class="cr"></label>
              </div>
              <label>Menu Title Hide</label>
            </div>
          </perfect-scrollbar>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
