import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;  
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}



const NavigationItemsAdmin = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'default',
            title: 'Default',
            type: 'item',
            url: '/dashboard/default'
          },
          {
            id: 'sucursal',
            title: 'Sucursal',
            type: 'item',
            url: '/dashboard/sucursal'
          },
          {
            id: 'summary',
            title: 'Summary',
            type: 'item',
            url: '/dashboard/summary'
          },
          {
            id: 'commissions',
            title: 'Commissions',
            type: 'item',
            url: '/dashboard/commission'
          },
          {
            id: 'product',
            title: 'Product',
            type: 'item',
            url: '/dashboard/product'
          }
          
        ]
      }
    ]
  },
  
  {
    id: 'app',
    title: 'APP',
    type: 'group',
    icon: 'icon-other',
    children: [
      {
        id: 'cat',
        title: 'Catalogs',
        type: 'collapse',
        icon: 'feather icon-box',

        children: [
          {
            id: 'sucursal',
            title: 'Branch',
            type: 'item',
            url: '/sucursal/list',
            classes: 'nav-item',
            icon: 'feather icon-home'
          },
          {
            id: 'usuario',
            title: 'User',
            type: 'item',
            url: '/usuario/list',
            classes: 'nav-item',
            icon: 'feather icon-user'
          }
        ]
      },    
      {
        id: 'comp',
        title: 'Compensation',
        type: 'collapse',
        icon: 'feather icon-globe',

        children: [         
          {
            id: 'vga',
            title: 'VGA',
            type: 'item',
            url: '/comprod/vga',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          },
          {
            id: 'upg',
            title: 'UPG',
            type: 'item',
            url: '/comprod/upg',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          },
          {
            id: 'bb',
            title: 'BB',
            type: 'item',
            url: '/comprod/bb',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          },
          {
            id: 'prod',
            title: 'Product',
            type: 'item',
            url: '/comprod/prod',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          },
          {
            id: 'stand',
            title: 'Stand Alone',
            type: 'item',
            url: '/comprod/stand',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          }
        ]
      },
      {
        id: 'pre',
        title: 'Pre-Commissions',
        type: 'collapse',
        icon: 'feather icon-settings',

        children: [
          {
            id: 'precom',
            title: 'Precomision',
            type: 'item',
            url: '/precomision/list',
            classes: 'nav-item',
            icon: 'feather icon-star'
          }
        ]
      },
      {
      id: 'venta',
      title: 'Sales',
      type: 'collapse',
      icon: 'feather icon-shopping-cart',
      children: [
          {
            id: 'lista',
            title: 'List',
            type: 'item',
            url: '/venta/list',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          }
        ] 
      },
      {
      id: 'reportes',
      title: 'Reports',
      type: 'collapse',
      icon: 'feather icon-pie-chart',
      children: [
          {
            id: 'acc',
            title: 'Rep. ACC',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'act',
            title: 'Rep. ACT',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'bbtv',
            title: 'Rep. BBTV',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'insu',
            title: 'Rep. INSU',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'int',
            title: 'Rep. INT',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'resumen',
            title: 'Rep. Summary',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          },
          {
            id: 'select',
            title: 'Rep. Select',
            type: 'item',
            url: 'javascript:',
            classes: 'nav-item',
            icon: 'feather icon-file'
          }
               
        ] 
      }
    ]
  }
];

const NavigationItemsBasic = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'default',
            title: 'Default',
            type: 'item',
            url: '/dashboard/default'
          },
          {
            id: 'sucursal',
            title: 'Sucursal',
            type: 'item',
            url: '/dashboard/sucursal'
          }
          
        ]
      }
    ]
  },
  
  {
    id: 'app',
    title: 'APP',
    type: 'group',
    icon: 'icon-other',
    children: [

      {
      id: 'venta',
      title: 'Sales',
      type: 'collapse',
      icon: 'feather icon-shopping-cart',
      children: [
          {
            id: 'lista',
            title: 'List',
            type: 'item',
            url: '/venta/list',
            classes: 'nav-item',
            icon: 'feather icon-grid'
          }
        ] 
      }
    ]
  }
];


@Injectable()
export class NavigationItem {
  get() {
      return NavigationItemsBasic;    
  };
  getmkMenu(suscripcion: string) {
    if (suscripcion=='ADMIN') {
      return NavigationItemsAdmin;
    } else {
      return NavigationItemsBasic;
    }
    
  }
}
